<template>
    <page-content title="视频监控">

        <a-card class="card" title="设备参数">

            <a-row :gutter="[16,16]">
                <a-col :span="6" v-for="item in paraArray">
                    <WaterParaView :model="item" :valueMap="valueMap"></WaterParaView>
                </a-col>
            </a-row>

        </a-card>


        <a-card
                style="width:100%"
                :title="cameraList.length==0?'未添加摄像头':false"
                :tab-list="cameraList"
                :active-tab-key="tabKey"
                @tabChange="onTabChange">

            <a-button v-if="cameraList.length==0" slot="extra" @click="clickAdd">添加摄像头</a-button>
            <a-button v-else slot="tabBarExtraContent" @click="clickAdd">添加摄像头</a-button>

            <EzuCamera v-if="cameraList.length>0" ref="ezuCamera" :camera="cameraList[0]"></EzuCamera>

        </a-card>


        <CameraEdit ref="cameraEdit" :id="deviceId" @success="getCameraList"></CameraEdit>
    </page-content>
</template>

<script>
    import BaseDeviceView from "../BaseDeviceView";
    import EzuCamera from "../_components/EzuCamera";
    import WaterParaView from "../_components/WaterParaView";
    import CameraEdit from "./CameraEdit";

    export default {
        extends:BaseDeviceView,
        components: {EzuCamera, CameraEdit, WaterParaView},
        data() {
            return {
                cameraList: [],
                tabKey: "",
                paraArray: [],
            }
        },
        computed: {
            camera() {
                return {
                    cameraSn: "F44130626",
                    cameraCode: "ZMIWSN"
                }
            }
        },
        created() {
            this.getCameraList()

            //获取参数和开关设置
            this.$get(`/web/iot/water/device/paras/${this.deviceId}`).then(r => {
                this.paraArray = r.data.paraArray
            })
        },
        methods: {
            onTabChange(key) {
                this.tabKey = key
            },
            clickAdd() {
                this.$refs.cameraEdit.show()
            },
            getCameraList() {
                this.$get(`web/iot/water/camera/${this.deviceId}`).then(suc => {
                    suc.data.forEach(item => {
                        item.key = item.id + ""
                        item.tab = item.name
                    })
                    this.cameraList = suc.data
                    if (this.cameraList.length > 0) {
                        this.tabKey = this.cameraList[0].id + ""
                        setTimeout(()=>{
                            this.$refs.ezuCamera.playLive()
                        },2000)

                    }
                })
            },

        }
    }
</script>

<style lang="less" scoped>
    .card {
        margin-bottom: 20px;
    }
</style>
