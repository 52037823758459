<template>

</template>

<script>
    export default {
        data(){
            return {
                deviceId:this.$route.params.deviceId,
                getDataInterval:null,
                device:{},
                valueMap:{}
            }
        },
        created() {
            this.deviceId = this.$route.params.deviceId

            this.getDeviceInfo()

            this.getDataInterval = setInterval(()=>{
                this.getDeviceData()
            },2000)
            this.getDeviceData()

        },
        destroyed() {
            clearInterval(this.getDataInterval)
        },

        methods:{
            getDeviceInfo(){
                this.$get(`web/iot/water/device/info/${this.deviceId}`).then(r => {
                    this.device = r.data;
                });
            },
            getDeviceData(){
                this.$get(`device/data/${this.deviceId}`).then(suc=>{
                    this.valueMap = suc.data
                })
            },
            control(key,value){

                const hide = this.$message.loading('正在设置...', 5);
                this.$post("device/control", {
                    deviceId: this.deviceId,
                    key: key,
                    value: value
                }).then(suc => {
                    // hide()
                }).catch(err => {
                    // hide()
                })
            },
            controlDevice(items) {
                let key = Object.keys(items)[0]
                let value = items[key]
                this.control(key,value)
            },
        }

    }
</script>

<style scoped>

</style>
